// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appointments-js": () => import("./../../../src/pages/appointments.js" /* webpackChunkName: "component---src-pages-appointments-js" */),
  "component---src-pages-babies-infant-feeding-coaching-js": () => import("./../../../src/pages/babies/infant-feeding-coaching.js" /* webpackChunkName: "component---src-pages-babies-infant-feeding-coaching-js" */),
  "component---src-pages-babies-newborn-check-js": () => import("./../../../src/pages/babies/newborn-check.js" /* webpackChunkName: "component---src-pages-babies-newborn-check-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-osteopathy-conditions-js": () => import("./../../../src/pages/osteopathy/conditions.js" /* webpackChunkName: "component---src-pages-osteopathy-conditions-js" */),
  "component---src-pages-osteopathy-cranial-osteopathy-js": () => import("./../../../src/pages/osteopathy/cranial-osteopathy.js" /* webpackChunkName: "component---src-pages-osteopathy-cranial-osteopathy-js" */),
  "component---src-pages-osteopathy-js": () => import("./../../../src/pages/osteopathy.js" /* webpackChunkName: "component---src-pages-osteopathy-js" */),
  "component---src-pages-osteopathy-osteopathy-and-babies-js": () => import("./../../../src/pages/osteopathy/osteopathy-and-babies.js" /* webpackChunkName: "component---src-pages-osteopathy-osteopathy-and-babies-js" */),
  "component---src-pages-osteopathy-osteopathy-and-pregnancy-js": () => import("./../../../src/pages/osteopathy/osteopathy-and-pregnancy.js" /* webpackChunkName: "component---src-pages-osteopathy-osteopathy-and-pregnancy-js" */),
  "component---src-pages-osteopathy-osteopathy-and-sports-injuries-js": () => import("./../../../src/pages/osteopathy/osteopathy-and-sports-injuries.js" /* webpackChunkName: "component---src-pages-osteopathy-osteopathy-and-sports-injuries-js" */),
  "component---src-pages-osteopathy-what-to-expect-js": () => import("./../../../src/pages/osteopathy/what-to-expect.js" /* webpackChunkName: "component---src-pages-osteopathy-what-to-expect-js" */),
  "component---src-pages-pilates-general-pilates-js": () => import("./../../../src/pages/pilates/general-pilates.js" /* webpackChunkName: "component---src-pages-pilates-general-pilates-js" */),
  "component---src-pages-pilates-prenatal-postnatal-pilates-js": () => import("./../../../src/pages/pilates/prenatal-postnatal-pilates.js" /* webpackChunkName: "component---src-pages-pilates-prenatal-postnatal-pilates-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-visual-language-js": () => import("./../../../src/pages/visual-language.js" /* webpackChunkName: "component---src-pages-visual-language-js" */),
  "component---src-pages-womens-health-postnatal-check-js": () => import("./../../../src/pages/womens-health/postnatal-check.js" /* webpackChunkName: "component---src-pages-womens-health-postnatal-check-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/article-list.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */)
}

